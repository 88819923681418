import styled from "styled-components";

const Spacer = styled.div`
  ${({ space, vertical }) => {
    const dimension = vertical ? 'height' : 'width';
    return `
      ${dimension}: ${space}px;
    `;
  }};
`;

export default Spacer;
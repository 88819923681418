import styled from "styled-components";

export const AboutUsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: center;
  width: 80vw;
  padding: 40px 20px;
  margin: auto;
  flex: 0.3;

  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;

export const AboutUsImg = styled.img`
  flex: 2;

  @media screen and (max-width: 1000px) {
    height: auto;
    width: auto;


  }

  @media screen and (max-width: 768px) {
    height: 200px;
    width: 250px;
    margin: auto;

  }
`;


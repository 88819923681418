import SectionTitle from "../base/SectionTitle";
import aboutUsImg from "../../assets/images/about-us-image.jpeg";

import {
  AboutUsWrapper,
  AboutUsImg,

} from "./styles"


const AboutUs = () => {
  return (
    <AboutUsWrapper id="about">
      <div>
        <SectionTitle
          title="About Us"
          text="Smart Data Links is a web platform where users can purchase Mobile Data Bundles, 
        VTU Airtime, Pay Electricity Bills, TV Subscription. 
        We have designed our website to accommodate user needs. 
        Providing users of our platform the opportunity to save cost,
        make fast, secured, efficient and rewarding purchases and 
        bill payments. Our internet/mobile data plans work with all devices including Android, 
        Iphone, Computers, Modems e.t.c. Data can be rollover if you re-subscribe before expiry date of current plan."
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <AboutUsImg src={aboutUsImg} alt="about-us" />
      </div>
    </AboutUsWrapper>
  );
};

export default AboutUs;

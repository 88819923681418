import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";
import smartLogo from "../../assets/images/smartLogo.png";

import {
  NavBarRoot,
  ImageContainer,
  Image,
  LinksRow,
  Link,
  LinkColumn,
  NavBarContainer,
  LogoText,
  LoginButton,
} from "./styles";
import { useScreenSize } from "../../hooks/useScreenSize";

const Navbar = () => {
  const value = useScreenSize(window.innerWidth);
  const [active, setActive] = useState(false);

  const isMobile = value <= 1000;

  return (
    <NavBarRoot>
      <NavBarContainer>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ImageContainer>
            <Image src={smartLogo} alt="logo" />
          </ImageContainer>
          <LogoText>SMARTDATALINKS</LogoText>
        </div>

        <div>
          {isMobile && <FaBars style={{
            marginRight: "20px"
          }} onClick={() => setActive(!active)} />}
          <LinkColumn active={active}>
            <div className="close-button">
              <AiOutlineCloseCircle onClick={() => setActive(!active)} />
            </div>
            <Link href="#home"> Home </Link>
            <Link href="#services">Our Services</Link>
            <Link href="#about">About Us</Link>
            <Link href="#pricing">Pricing</Link>
            <Link href="#faq">FAQ</Link>

            <Link href="https://app.smartdatalinks.ng">Login</Link>
          </LinkColumn>
          <LinksRow>
            <Link href="#services">Our Services</Link>
            <Link href="#about">About Us</Link>
            <Link href="#pricing">Pricing</Link>
            <Link href="#faq">FAQ</Link>
            <LoginButton href="https://app.smartdatalinks.ng">
              Login
            </LoginButton>
          </LinksRow>
        </div>
      </NavBarContainer>
    </NavBarRoot>
  );
};

export default Navbar;

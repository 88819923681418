import {
  DownloadContainer,
  DownloadWrapper,
  ImageWrapper,
  DownloadHeader,
  DownloadText,
} from "./styles";

import downloadImg from "../../assets/images/pretty-cheerful-african-lady-holding-laptop-isolated 1.png";
import qrCode from "../../assets/images/Qrcode.png";
import playStore from "../../assets/images/play-store.svg";
import appStore from "../../assets/images/app-store.svg";
import Spacer from "../base/Spacer";

export const Download = () => {
  return (
    <DownloadContainer>
      <DownloadWrapper>
        <div>
          <ImageWrapper>
            <img src={downloadImg} alt="download" />
          </ImageWrapper>
        </div>

        <div style={{ maxWidth: "600px" }}>
          <DownloadHeader>
            Download the app now to embark on your path to hassle-free payments
          </DownloadHeader>
          <Spacer vertical space={20} />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img className="download-image" src={qrCode} alt="qrCode" />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <DownloadText>
                Use your phones camera to scan and download the app
              </DownloadText>
              <Spacer vertical space={20} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-betwee",
                  alignItems: "flex-end",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {" "}
                <a
                  href="https://play.google.com/store/apps/details?id=com.x.smart_data"
                  target="_blank"
                >
                  <img src={playStore} alt="play-store" />
                </a>
                <a
                  href="https://apps.apple.com/ng/app/smartdatalinks-pro/id6474259559"
                  target="_blank"
                >
                  <img src={appStore} alt="app-store" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </DownloadWrapper>
    </DownloadContainer>
  );
};

export default Download;

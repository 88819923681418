import Navbar from "./components/Navbar/Navbar";
import Hero from "./components/Hero/Hero";
import Services from "./components/Services/Services";
import AboutUs from "./components/AboutUs/AboutUs";
import Stats from "./components/Stats/Stats";
import Download from "./components/Download/Download";
import Testimonials from "./components/Testimonials/Testimonials";
import PaymentPlans from "./components/Plans/PaymentPlans";
import FAQ from "./components/FAQ/FAQ";
import Footer from "./components/Footer/Footer";
import OurPartners from "./components/OurPartners/OurPartners";
import WhyChooseUs from "./components/WhyChooseUs/WhyChooseUs";
import {  DataProvider } from "./hooks/dataProvider";

function App() {
  return (
    <div>
      <DataProvider>
        <Navbar />
        <Hero />
        <OurPartners />
        <AboutUs />
        <Services />
        <WhyChooseUs />
        <Stats />
        <Download />
        <Testimonials />
        <PaymentPlans />
        <FAQ />
        <Footer />
      </DataProvider>
    </div>
  );
}

export default App;

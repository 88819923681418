import Spacer from "../base/Spacer";

import tiktokIcon from "../../assets/images/tiktok.svg";
import facebookIcon from "../../assets/images/facebook.png";
import instagramIcon from "../../assets/images/Instagram.png";
import whatsappIcon from "../../assets/images/WhatsApp.png";

import {
  FooterContainer,
  FooterWrapper,
  Logo,
  ServicesBox,
  ServicesContainer,
  ServicesTitle,
  ServicesText,
  SubscribeTitle,
  SubscriptionButton,
  EmailInput,
  Line,
  FooterBottom,
  SocialMediaIconsContainer,
  SocialMediaIcon,
} from "./styles";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        {/* <Logo
          src={smartLogo}
          alt="logo"
          id="logo"
        /> */}
        <Spacer vertical space={40} />
        <ServicesContainer>
          <ServicesBox>
            <div>
              <ServicesTitle>Our Services</ServicesTitle>
              <Spacer vertical space={40} />
              <ServicesText>Buy Data</ServicesText>
              <Spacer vertical space={20} />
              <ServicesText> Airtime TopUp</ServicesText>
              <Spacer vertical space={20} />
              <ServicesText>Cable Subscription</ServicesText>
              <Spacer vertical space={20} />
              <ServicesText>Bill Payment</ServicesText>
            </div>
            <div>
              <ServicesTitle>Other Links</ServicesTitle>
              <Spacer vertical space={40} />
              <ServicesText>Home</ServicesText>
              <Spacer vertical space={20} />
              <ServicesText>About Us</ServicesText>
              <Spacer vertical space={20} />
              <ServicesText>Our Features</ServicesText>
              <Spacer vertical space={20} />
              <ServicesText>Our Services</ServicesText>
              <Spacer vertical space={20} />
              <ServicesText>FAQ</ServicesText>
            </div>
          </ServicesBox>
          <div className="subscribe-container">
            <SubscribeTitle>Subcribe</SubscribeTitle>
            <Spacer vertical space={10} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "#fff",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                borderRadius: "20px",
              }}
            >
              <EmailInput placeholder="Email Us" />
              <SubscriptionButton>Click to Subscribe</SubscriptionButton>
            </div>
            <Spacer vertical space={10} />
            <SubscribeTitle>Email Us</SubscribeTitle>
            <Spacer vertical space={10} />
            <ServicesText> Smartdatalinks@gmail.com</ServicesText>
            <ServicesText>Support@smartdatalinks.ng</ServicesText>
            <Spacer vertical space={10} />

            <SubscribeTitle>Address</SubscribeTitle>
            <Spacer vertical space={10} />
            <ServicesText>
              {" "}
              Address HD PLAZA, AHMADU BELLO WAY, BAUCHI, BAUCHI STATE, NIGERIA.
            </ServicesText>
            <Spacer vertical space={10} />
            <SubscribeTitle>Call Us</SubscribeTitle>
            <Spacer vertical space={10} />
            <ServicesText> 08184130156</ServicesText>
          </div>
        </ServicesContainer>
      </FooterWrapper>
      <Line></Line>
      <FooterBottom>
        <ServicesText>(C) Copyright 2023. All Rights Reserved</ServicesText>
        <SocialMediaIconsContainer>
          {/* <a href="https://www.tiktok.com/@topify.ng" target="_blank" rel="noreferrer">
              <SocialMediaIcon src={tiktokIcon} /> </a> */}
          <a
            href="https://web.facebook.com/smartdatalinks"
            target="_blank"
            rel="noreferrer"
          >
            <SocialMediaIcon src={facebookIcon} />
          </a>
          <a
            href="https://www.instagram.com/smartdatalinks/"
            target="_blank"
            rel="noreferrer"
          >
            <SocialMediaIcon src={instagramIcon} />
          </a>
          <a href="#">
            <SocialMediaIcon src={whatsappIcon} />
          </a>
        </SocialMediaIconsContainer>
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;

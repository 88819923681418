import styled from "styled-components";
export const FooterWrapper = styled.div`
  width: 80vw;
  margin: auto;
  padding: 40px 0;
`;

export const FooterContainer = styled.div`
  background-color: #094E8E;
`;

export const ServicesBox = styled.div`
  display: flex;
  width: 386px;
  justify-content: space-between;
`;

export const Logo = styled.img`
  width: 125px;
  height: 53.932px;
`;

export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .subscribe-container {
    @media screen and (max-width: 768px) {
        display: none;
        
    }
  }
`;

export const ServicesTitle = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
`;

export const SubscribeTitle = styled.div`
  color: #fff;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const EmailInput = styled.input`
  background-color: #fff;
  border: none;
  padding: 40px;
  border-radius: 20px;
  font-size: 16px;
  &:focus {
    outline: none
  }


 

`;

export const SubscriptionButton = styled.button`
  background-color: #094E8E;;
  color: #fff;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  font-size: 16px;
  padding: 30px 51px;
  margin-right: 20px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #001d3a;
  }
`;

export const Line = styled.div`
  height: 2px;
  flex-shrink: 0;
  border-top: 1px solid white;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
`;


export const ServicesText = styled.div`
  font-size: 12px;
  font-weight: medium;
  color: white;
`;

export const SocialMediaIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

   a {
    text-decoration: none;
   }
`;

export const SocialMediaIcon = styled.img`
  width: 2.5rem;
  flex-shrink: 0;
`;

export const FooterBottom = styled.div `
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80vw;
    margin: auto;
    padding: 20px 0px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
        
    }
`
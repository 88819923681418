import styled from "styled-components";
import Spacer from "../base/Spacer";

const ServicesContainer = styled.div`
     /* background: rgba(85, 172, 238, 0.1); */
`

const ServicesWrapper = styled.div`
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
  flex-direction: row;
  width: 80vw;
  margin: auto;
  flex-wrap: wrap;
`;

const ImageWrapper = styled.div`
  width: 585px;
  height: 828.46px;
  margin-left: -20px;
  img {
    height: auto;
    width: 100%;
    object-fit: contain;
  }

  @keyframes slideInRight {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(0);
      }
    }

    animation: slideInRight 0.5s ease-out;

  @media screen and (max-width: 600px) {
    display: none;
    
  }
`;

const ServicesRoot = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 30px;
  width: 100%;
  padding: 4px 0;

@media only screen and (max-width: 768px) {
 grid-template-columns: repeat(1, 1fr);
 gap: 20px;
}
  

`;

const ServiceCardContainer = styled.div`
  overflow: hidden;
  background: #5aabee27;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 27px 30px;
  border-radius: 20px;
`;

const ServiceImage = styled.div`
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  flex-shrink: 0;
`;

const ServiceTitle = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: white;
  font-family: "Poppins";
  color: #094E8E;
`;

const ServiceDescription = styled.div`
  text-align: center;
  font-size: 14px;
  color: white;
  width: 200px;
  color: #094E8E;
`;



const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesWrapper>
          <ServicesRoot>
            <ServiceCard
              imageUrl="https://file.rendit.io/n/phMuznQlxDcqrLgPOmpA.png"
              title="Buy Airtime"
              description="Making online recharge cheap and easy to buy at all time of the day."
            />
            <ServiceCard
              imageUrl="https://file.rendit.io/n/sIaKFWSQc36fKCljsodZ.png"
              title="Pay Utility Bills"
              description="Because we understand your needs, we have made bills and utilities payment more convenient."
            />
            <ServiceCard
              imageUrl="https://file.rendit.io/n/vV7Bw7XmsrP5WTy1kqi9.png"
              title="Airtime to Cash"
              description="We offer this service at a very good attractive rate login to see current conversion rate."
            />
            <ServiceCard
              imageUrl="https://file.rendit.io/n/tVHhJOjYs3EmiGO6AXtt.png"
              title="Buy Data"
              description="Start enjoying this very low rates Data plan for your internet browsing data bundle."
            />
            <ServiceCard
              imageUrl="https://file.rendit.io/n/WOq3HWessuk01nxKoyLz.png"
              title="Cable Subscribtions"
              description="Instantly Activate Cable subscription with favourable discount compare to others..."
            />
            <ServiceCard
              imageUrl="https://file.rendit.io/n/UKCDnebX0yzenVwa2926.png"
              title="Card Printing"
              description="Elevate your brand with our comprehensive card printing services, offering customized designs for both airtime recharge cards and data cards, ensuring visual appeal, user-friendly instructions, and top-notch security features."
            />
          </ServicesRoot>
  
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;

const ServiceCard = ({ imageUrl, title, description, ...restStyles }) => {
  return (
    <ServiceCardContainer {...restStyles}>
      <ServiceImage imageUrl={imageUrl}>
        <img src={imageUrl} alt={title} />
      </ServiceImage>
      <Spacer vertical space={20} />
      <div>
        <ServiceTitle>{title}</ServiceTitle>
        <Spacer vertical space={10} />
        <ServiceDescription>{description}</ServiceDescription>
      </div>
    </ServiceCardContainer>
  );
};
